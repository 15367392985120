import axios from 'axios';

import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/login/recoverySession',
    login: '/login',
    register: '/users/users',
    verifyEmail: '/users/users/verifyRegistration',
    forgotPassword: '/login/resetPassword',
    catalogs: '/users/users/public/catalogs',
    logout: 'login/logout',
  },
  user: {
    list: '/Users/Users',
    create: '/Users/Users',
    update: '/Users/Users',
    delete: '/Users/Users',
    changePassword: '/Users/Users/cambiarContrasena',
    saveChangesProfile: '/Users/Users/saveChangesProfile',
  },
  rol: {
    list: '/inherited/roles',
    create: '/inherited/roles',
    update: '/inherited/roles',
    delete: '/inherited/roles',
  },
  relationRolService: {
    list: '/inherited/serviciosRol/consultarRegistrosProInd',
    create: '/inherited/serviciosRol/dinamicAccordion',
  },
  genders: {
    list: '/rails/catalogs/Genders',
    create: '/rails/catalogs/Genders',
    delete: (id) => (`/rails/catalogs/Genders/${id}`),
    update: (id) => (`/rails/catalogs/Genders/${id}`),
  },
  countries: {
    list: '/rails/catalogs/Countries',
    create: '/rails/catalogs/Countries',
    delete: (id) => (`/rails/catalogs/Countries/${id}`),
    update: (id) => (`/rails/catalogs/Countries/${id}`),
    listClassifications: (id) => (`/rails/catalogs/Countries/countries_classification/${id}`),
  },
  cancelReasons: {
    list: '/rails/catalogs/cancelReasons',
    create: '/rails/catalogs/cancelReasons',
    delete: (id) => (`/rails/catalogs/cancelReasons/${id}`),
    update: (id) => (`/rails/catalogs/cancelReasons/${id}`),
  },
  apiSettings: {
    list: '/rails/catalogs/apiSettings',
    create: '/rails/catalogs/apiSettings',
    delete: (id) => (`/rails/catalogs/apiSettings/${id}`),
    update: (id) => (`/rails/catalogs/apiSettings/${id}`),
  },
  statuses: {
    list: '/rails/catalogs/statuses',
    create: '/rails/catalogs/statuses',
    delete: (id) => (`/rails/catalogs/statuses/${id}`),
    update: (id) => (`/rails/catalogs/statuses/${id}`),
  },
  deleteLocationCategoryEventReason: {
    list: '/rails/catalogs/DeleteLocationCategoryEventReasons',
    create: '/rails/catalogs/DeleteLocationCategoryEventReasons',
    delete: (id) => (`/rails/catalogs/DeleteLocationCategoryEventReasons/${id}`),
    update: (id) => (`/rails/catalogs/DeleteLocationCategoryEventReasons/${id}`),
  },
  medals: {
    list: '/rails/catalogs/medals',
    create: '/rails/catalogs/medals',
    delete: (id) => (`/rails/catalogs/medals/${id}`),
    update: (id) => (`/rails/catalogs/medals/${id}`),
  },
  clasifications: {
    list: '/rails/catalogs/clasifications',
    create: '/rails/catalogs/clasifications',
    delete: (id) => (`/rails/catalogs/clasifications/${id}`),
    update: (id) => (`/rails/catalogs/clasifications/${id}`),
  },
  categories: {
    list: (id) => (`/rails/catalogs/categories/clasification/${id}`),
    create: '/rails/catalogs/categories',
    delete: (id) => (`/rails/catalogs/categories/${id}`),
    update: (id) => (`/rails/catalogs/categories/${id}`),
  },
  dashboardHome: {
    filtersData: `/dashboards/StartDashboard/filtersData`,
    dataDepartmentsGuatemala: '/dashboards/StartDashboard/dataDepartmentsGuatemala',
    dataDepartmentsMunicitiesGuatemala: '/dashboards/StartDashboard/dataDepartmentsMunicitiesGuatemala',
    dataDepartmentsMunicitiesZipCodeGuatemala: '/dashboards/StartDashboard/dataDepartmentsMunicitiesZipCodeGuatemala',
  },
  feeds: {
    list: (id) => id !== 0 ? (`/feeds/public/${id}`) : ('/feeds/public')
  },
};
