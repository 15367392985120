import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const APP_LINKS = {
  ANDROID: {
    APP_URL: process.env.REACT_APP_APP_URL_ANDROID,  
    STORE_URL: process.env.REACT_APP_STORE_URL_ANDROID,
  },
  IOS: {
    APP_URL: process.env.REACT_APP_STORE_URL_IOS,      
    STORE_URL: process.env.REACT_APP_APP_URL_IOS,
  }
};

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
export const PATH_AFTER_REGISTER = paths.auth.jwt.verify;