import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { GuestGuard, AuthGuard } from 'src/auth/guard';
import ContainerPosts from 'src/layouts/auth/container-posts';

import { SplashScreen, LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// Public
const PostItemPublic = lazy(() => import('src/pages/postPublics/postPublic'));
const PostItemParamsPublic = lazy(() => import('src/pages/postPublicsParams/postPublicParams'));

// ----------------------------------------------------------------------

const publicRoute = {
  path: '', // root
  element: (
    <Suspense fallback={<LoadingScreen />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      path: 'posts',
      element: (
        <GuestGuard>
          <ContainerPosts>
            <PostItemPublic />
          </ContainerPosts>
        </GuestGuard>
      ),
    },
    {
      path: 'posts/:id', // Ruta con parámetro dinámico
      element: (
        <GuestGuard>
          <ContainerPosts>
            <PostItemParamsPublic />
          </ContainerPosts>
        </GuestGuard>
      ),
    },
  ],
};

export const publicRoutes = [
  {
    path: '', // root
    children: [publicRoute],
  },
];
