// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
      forgotPassword: `${ROOTS.AUTH}/jwt/forgotPassword`,
      verify: `${ROOTS.AUTH}/jwt/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    maintenance: {
      genders: {
        list: `${ROOTS.DASHBOARD}/maintenance/genders`,
        new: `${ROOTS.DASHBOARD}/maintenance/genders/new`,
      },
      countries: {
        list: `${ROOTS.DASHBOARD}/maintenance/countries`,
        new: `${ROOTS.DASHBOARD}/maintenance/countries/new`,
      },
      cancelReasons: {
        list: `${ROOTS.DASHBOARD}/maintenance/cancelReasons`,
        new: `${ROOTS.DASHBOARD}/maintenance/cancelReasons/new`,
      },
      apiSettings: {
        list: `${ROOTS.DASHBOARD}/maintenance/apiSettings`,
        new: `${ROOTS.DASHBOARD}/maintenance/apiSettings/new`,
      },
      statuses: {
        list: `${ROOTS.DASHBOARD}/maintenance/statuses`,
        new: `${ROOTS.DASHBOARD}/maintenance/statuses/new`,
      },
      deleteLocationCategoryEventReason: {
        list: `${ROOTS.DASHBOARD}/maintenance/deleteLocationCategoryEventReason`,
        new: `${ROOTS.DASHBOARD}/maintenance/deleteLocationCategoryEventReason/new`,
      },
      medals: {
        list: `${ROOTS.DASHBOARD}/maintenance/medals`,
        new: `${ROOTS.DASHBOARD}/maintenance/medals/new`,
      },
      clasifications: {
        list: `${ROOTS.DASHBOARD}/maintenance/clasifications`,
        new: `${ROOTS.DASHBOARD}/maintenance/clasifications/new`,
      },
    },
    configuration: {
      user: {
        new: `${ROOTS.DASHBOARD}/configuration/users/new`,
        list: `${ROOTS.DASHBOARD}/configuration/users`,
      },
      roles: {
        new: `${ROOTS.DASHBOARD}/configuration/roles/new`,
        list: `${ROOTS.DASHBOARD}/configuration/roles`,
      },
      relationRolService: {
        list: `${ROOTS.DASHBOARD}/configuration/ServicioRol`,
      },
    },
  },
};
