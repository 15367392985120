import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';
import { useResponsive } from 'src/hooks/use-responsive';
import { bgGradient } from 'src/theme/css';
import Logo from 'src/components/logo';

// ----------------------------------------------------------------------

export default function ContainerPosts({ children, image, title }) {
  const theme = useTheme();
  const mdUp = useResponsive('up', 'md');

  const renderContent = (
    <Box
      sx={{
        width: 1,
        mx: 'auto', // Margen horizontal auto, centrado
        maxWidth: { xs: '100%'}, // Similar a maxWidth del Container
      }}
    >
      {children}
    </Box>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      spacing={10}
      alignItems="center"
      justifyContent="center"
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
          imgUrl: '/assets/background/overlay_2.jpg',
        }),
      }}
    />
  );

  return (
    <Stack
      component="main"
      direction="column"
      sx={{
        minHeight: '100vh',
      }}
    >
      {renderContent}
    </Stack>
  );
}

ContainerPosts.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  title: PropTypes.string,
};
