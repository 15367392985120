import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light; // '#B985F4'

  const PRIMARY_MAIN = theme.palette.primary.main; // '#7635dc'

  const PRIMARY_DARK = theme.palette.primary.dark; // '#431A9E'

  // OR using local (public folder)
  // -------------------------------------------------------
  const logo =  (
    <Box
      component="img"
      src= {other.pathLogo}
      sx={{ width: 90, height: 40, cursor: 'pointer',alignContent: 'center', alignItems: 'center', ...sx }}
    />
  );




  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents', alignContent: 'center', alignItems: 'center' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
