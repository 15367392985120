import { Navigate, useRoutes } from 'react-router-dom';
import { useMemo } from 'react';

import { PATH_AFTER_LOGIN } from 'src/config-global';
import { useAuthContext } from 'src/auth/hooks';

import { mainRoutes } from './main';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { publicRoutes } from './public';
// ----------------------------------------------------------------------

export default function Router() {

  const { user: dataUser } = useAuthContext();

  const componentsAccessUser = useMemo(() => {
    if (dataUser) {
      return dataUser?.rutas?.map(ruta => {
        let components = [];
        if (ruta.subMenu) {
          components = ruta.subMenu.map((component) => component.componente.toLowerCase());
        } else {
          components = [ruta.componente.toLowerCase()];
        }
        return components;
      }).flat();
    }
    return [];
  }, [dataUser]);

  const filteredDashboardRoutes = useMemo(() => {
    if (componentsAccessUser?.length > 0) {
      return dashboardRoutes.map(route => {
        if (route.children) {
          const filteredChildren = route.children.map(section => {
            let data = {};
            if (section.index) {
              data = { ...section };
            } else if (section.path === 'publicas') {
              data = { ...section };
            } else if (section.children) {
              data = {
                ...section,
                children: section.children.filter(child => 
                  componentsAccessUser.includes(child.service.toLowerCase())
                ),
              };
            }
            return data;
          });
          return { ...route, children: filteredChildren };
        }
        return route;
      });
    }
    return dashboardRoutes;
  }, [componentsAccessUser]);

  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...filteredDashboardRoutes,

    // Main routes
    ...mainRoutes,

    // public posts within session
    ...publicRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
